import React from 'react'

export const ErrorPage = () => {
  return (
    <div>This page could not be found, please shop around!</div>
  )
}


export default ErrorPage
